<template>
  <div style="padding-top: 100px;">
    <div class="container">
      <div class="d-flex justify-content-between">
        <div class="row text-left header d-flex justify-content-start">
          <router-link to="/congress" class="back-icon">
            <font-awesome-icon icon="arrow-left"/>
          </router-link>
          <div class="title">
            <h3>{{congress.name}}</h3>
            <small>{{congressLocation.name}} &bullet; {{congressLocation.address}}, {{congressLocation.city}}, {{congressLocation.state}} {{congressLocation.zip}}</small>
          </div>
        </div>
      </div>
      <b-tabs card class="custom-card" lazy>
        <b-tab title="Home" active class="m-2">
          <congress-interaction-list-view/>
        </b-tab>
        <!-- <b-tab title="Locations">
          <congress-location-view />
        </b-tab> -->
        <b-tab title="Reports">
          <congress-report-view />
        </b-tab>
        <!-- <b-tab title="Support">
          <congress-support-view />
        </b-tab> -->
      </b-tabs>
    
    </div>
    <!-- <interaction-modal v-if="showInteraction" @close="closeInteractionModal" title="Add Interaction"></interaction-modal> -->
    <!-- <view-interaction-modal v-if="showInteractionDetail" @close="closeViewInteractionModal"></view-interaction-modal> -->
    
  </div>
</template>

<script>
import axios from 'axios'
import { forEach } from 'lodash'
import InteractionModal from '../components/InteractionModal.vue'
// import ViewInteractionModal from '../components/ViewInteractionModal.vue'
// import { http, authHeader } from '@/config/base-url'
import CongressInteractionListView from '../components/CongressInteractionListView.vue'
import CongressLocationView from '../components/CongressLocationView.vue'
import CongressReportView from '../components/CongressReportView.vue'
import CongressSupportView from '../components/CongressSupportView.vue'
export default {
  name: 'CongressView',
  components: {
    InteractionModal,
    // ViewInteractionModal,
    CongressInteractionListView,
    CongressLocationView,
    CongressReportView,
    CongressSupportView
  },
  data() {
    return {
      mainProps: { width: 55, height: 55, class: 'list-img' },
      tabType: 'attending',
      interactionType: 'attending',
      showInteraction: false,
      showInteractionDetail: false,
      showLocation: false,
      title: '',
      congress: {},
      congressLocation: {},
      meetings: [],
      interactionCount: '',
      conventionId: this.$route.params.name,
    }
  },
  computed: {
  },
  methods: {
    // addInteraction: function() {
    //   this.showInteraction = true
    //   document.body.classList.add("modal-open");
    // },
    // closeInteractionModal: function(){
    //   this.showInteraction = false
    //   setTimeout(() => {
    //     document.body.classList.remove("modal-open");
    //   }, 200)
      
    // },
    viewInteractionDetail: function() {
      this.showInteractionDetail = true
      document.body.classList.add("modal-open");
    },
    closeViewInteractionModal: function() {
      this.showInteractionDetail = false
      setTimeout(() => {
        document.body.classList.remove("modal-open");
      }, 200)
    },
    getConventionDetails: function () {
      axios.get(`https://simpsonapi.com/v2/conventions/${this.$route.params.name}`, {
        headers: {
          'X-Access-Token': `${localStorage.getItem('token')}`
        }
      }).then((res) => {
        console.log(res.data)
        this.congress = res.data
        if(this.congress.location) {
          this.congressLocation = res.data.location
        }
      })
    },
    
  },
  mounted () {
    console.log(this.$route.params.name)
    this.getConventionDetails()
  },

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
body.modal-open {
  overflow: hidden;
}

.header {
  padding-bottom: 16px;
}
.custom-card {
  border-top: 1px solid rgba(0,0,0,.125);
}

.add-btn {
  height: 40px;
  margin-top: 15px;
  font-weight: 600;
}

.back-icon {
  font-size: 22px;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: #000;
}

.title {
  padding-left: 15px;
}

.title > h3 {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-weight: bold;
}

.title > small {
  font-size: 14px;
  margin-top: 0px;
}

.list-item {
  padding-left: 16px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.interaction-type {
  margin-bottom: 16px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types > input:nth-of-type(4):checked ~ label:last-of-type:before {
  transform: translateX(calc(300% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(25% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>