<template>
  <div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-primary add-btn" @click="addInteraction"><font-awesome-icon icon="plus"/>&nbsp;Add Interaction</button>
    </div>
    <div class="interaction-type">
      <div class="flight-types">
        <input type="radio" name="flight-type" value="attending" id="attending" v-model="currentTab" @click="showCurrentTab('attending')"/>
        <label for="attending">Attending</label>

        <input type="radio" name="flight-type" value="created" id="created" v-model="currentTab" @click="showCurrentTab('created')"/>
        <label for="created">Created</label>

        <input type="radio" name="flight-type" value="all" id="all" v-model="currentTab" @click="showCurrentTab('all')"/>
        <label for="all">All</label>

        <input type="radio" name="flight-type" value="completed" id="completed" v-model="currentTab" @click="showCurrentTab('completed')"/>
        <label for="completed">Completed</label>
      </div>
      <small>{{interactionCount}} interactions</small>
    </div>
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="currentTab == 'attending' && hasCompletionForm == true">
      <div v-for="(items, index) in allMeetings" :key="index">
        <h4 class="text-left pt-2 mt-2 mb-0">{{formatDate(index)}}</h4>
        <div class="row">
          <div class="col-6 my-3" v-for="(value, i) in sortData(items)" :key="i">
            <div class="card" style="cursor:pointer;" v-if="value.ese_attending.length == 1">
              <div class="card-body p-1" @click="viewInteractionDetail(value)">
                <div class="text-left row justify-content-start py-2 ml-3">
                  <b-img v-bind="mainProps" rounded="circle" :src="getImg(value.ese_attending[0])"></b-img>
                  <div class="list-item" >
                    <h2>{{value.ese_attending[0].first_name}} {{value.ese_attending[0].last_name}}</h2>
                    <h4 class="text-muted">{{value.meeting_location ? value.meeting_location.name : 'Location TBD'}}</h4>
                    <p class="text-muted">{{formatTime(value.start_time)}}</p>  
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                  <div>
                    <button class="btn btn-sm btn-outline-success" @click="interactionCompleted(value)"><font-awesome-icon icon="check-circle" style="color:#2dd36f;"/> Completed</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button class="btn btn-sm btn-outline-danger" @click="didNotShow(value)"><font-awesome-icon icon="times-circle" style="color:#eb445a;"/> Did Not Show</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card" style="cursor:pointer;">
              <div class="card-body p-1" @click="viewInteractionDetail(value)">
                <div class="text-left d-flex justify-content-start p-2">
                  <div class="row w-100">
                    <div class="col-6 py-1" v-for="(item, index) in value.ese_attending" :key="index">
                      <div class="d-flex justifiy-content-start align-items-center px-2">
                        <b-img v-bind="mainPropsDouble" rounded="circle" :src="getImg(item)"></b-img>
                        <h2 style="font-size: 18px; margin-left: 10px; margin-top: 10px;">{{item.first_name}} {{item.last_name}}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-left list-item py-2">
                  <h4 class="text-muted">{{value.meeting_location ? value.meeting_location.name : 'Location TBD'}}</h4>
                  <p class="text-muted">{{formatTime(value.start_time)}}</p>  
                </div>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                  <div>
                    <button class="btn btn-sm btn-outline-success" @click="interactionCompleted(value)"><font-awesome-icon icon="check-circle" style="color:#2dd36f;"/> Completed</button>
                    <button class="btn btn-sm btn-outline-danger" @click="didNotShow(value)"><font-awesome-icon icon="times-circle" style="color:#eb445a;"/> Did Not Show</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="(items, index) in allMeetings" :key="index">
        <h4 class="text-left pt-2 mt-2 mb-0">{{formatDate(index)}}</h4>
        <div class="row">
          <div class="col-6 my-3" v-for="(value, i) in sortData(items)" :key="i">
            <div class="card" style="cursor:pointer;" v-if="value.ese_attending.length == 1">
              <div class="card-body p-1" @click="viewInteractionDetail(value)">
                <div class="text-left row justify-content-start py-2 ml-3">
                  <b-img v-bind="mainProps" rounded="circle" :src="getImg(value.ese_attending[0])"></b-img>
                  <div class="list-item" >
                    <h2>{{value.ese_attending[0].first_name}} {{value.ese_attending[0].last_name}}</h2>
                    <h4 class="text-muted">{{value.meeting_location ? value.meeting_location.name : 'Location TBD'}}</h4>
                    <div class="row justify-content-start px-3">
                      <div class="text-muted">{{formatTime(value.start_time)}}</div>
                      <div class="text-muted pl-3" v-if="value.is_completed && !value.did_not_show"><b-badge variant="success">Completed</b-badge></div>
                      <div class="text-muted pl-3" v-if="value.did_not_show"><b-badge variant="danger">Did Not Show</b-badge></div>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="card-footer" v-if="currentTab == 'created'">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                  <button class="btn btn-sm btn-outline-primary" @click="editInteraction(value)"><font-awesome-icon icon="edit"/> Edit</button>
                </div>
              </div>
              <div class="card-footer" v-else-if="value.is_completed">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                </div>
              </div>
            </div>
            <div v-else class="card" style="cursor:pointer; position:relative;">
              <div class="card-body p-1" @click="viewInteractionDetail(value)">
                <div class="text-left d-flex justify-content-start p-2">
                  <div class="row w-100">
                    <div class="col-6 py-1" v-for="(item, index) in value.ese_attending" :key="index">
                      <div class="d-flex justifiy-content-start align-items-center px-2">
                        <b-img v-bind="mainPropsDouble" rounded="circle" :src="getImg(item)"></b-img>
                        <h2 style="font-size: 18px; margin-left: 10px; margin-top: 10px;">{{item.first_name}} {{item.last_name}}</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-left list-item py-2">
                  <h4 class="text-muted">{{value.meeting_location ? value.meeting_location.name : 'Location TBD'}}</h4>
                  <div class="row justify-content-start px-3">
                      <div class="text-muted">{{formatTime(value.start_time)}}</div>
                      <div class="text-muted pl-3" v-if="value.is_completed && !value.did_not_show"><b-badge variant="success">Completed</b-badge></div>
                      <div class="text-muted pl-3" v-if="value.did_not_show"><b-badge variant="danger">Did Not Show</b-badge></div>
                    </div>   
                </div>
              </div>
              <div class="card-footer" v-if="currentTab == 'created'">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                  <button class="btn btn-sm btn-outline-primary" @click="editInteraction(value)"><font-awesome-icon icon="edit"/> Edit</button>
                </div>
              </div>
              <div class="card-footer" v-else-if="value.is_completed">
                <div class="d-flex justify-content-between">
                  <button class="btn btn-sm btn-outline-primary" @click="showComments(value)">Comments</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <view-interaction-modal v-if="showInteractionDetail" @close="closeViewInteractionModal" :data="interactionData"></view-interaction-modal>
    <interaction-modal v-if="showInteraction" @close="closeInteractionModal" :edit-data="editData" :title="interactionTitle"></interaction-modal>
    <interaction-completed-modal v-if="showInteractionCompleted" :id="interactionId" @close="closeInteractionCompletedModal"></interaction-completed-modal>
    <interaction-comments-modal v-if="showInteractionComments" :id="interactionId" :convention="conventionId" @close="closeInteractionCommentsdModal"></interaction-comments-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { http, authHeader } from '@/config/base-url'
import ViewInteractionModal from '../components/ViewInteractionModal.vue'
import InteractionModal from '../components/InteractionModal.vue'
import InteractionCompletedModal from '../components/InteractionCompletedModal.vue'
import InteractionCommentsModal from '../components/InteractionCommentsModal.vue'
export default {
  name: 'CongressInteractionListView',
  components: {
    ViewInteractionModal,
    InteractionModal,
    InteractionCompletedModal,
    InteractionCommentsModal
  },
  data() {
    return {
      mainProps: { width: 55, height: 55, class: 'list-img' },
      mainPropsDouble: { width: 35, height: 35, class: 'list-img' },
      hasCompletionForm: false,
      currentTab: 'attending',
      currentUser: JSON.parse(localStorage.getItem("user") || '{}').name,
      meetings: [],
      allMeetings: [],
      interactionCount: '0',
      commentsCount: 0,
      showInteractionDetail: false,
      showInteraction: false,
      showInteractionCompleted: false,
      showInteractionComments: false,
      conventionId: this.$route.params.name,
      interactionData: '',
      editData: {},
      interactionId: '',
      loading: false,
      interactionTitle: '',
      items: []
    }
  },
  computed: {
    
  },
  methods: {
    
    sortObj: function(obj) {
      return Object.keys(obj).sort().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
    },

    sortData: function(items) {
      return items.slice().sort((a,b) => {
        let da = new Date(a.start_time),
            db = new Date(b.start_time);
        return da - db;
      })
    },
    updateCompleted: function() {
      this.getData()
    },

    getInteractionComments: function (id) {
      http.get(`v2/conventions/interactions/${id}/comments`, {
        headers: authHeader
      }).then((res) =>{
        this.commentsCount = res.data.length
      })
    },

    getCommentCount: function (id, index) {
      console.log(id, index)
      // this.getInteractionComments(id)
      // return this.commentsCount
    },

    

    addInteraction: function() {
      this.editData = null
      this.showInteraction = true
      this.interactionTitle = 'Add Interaction'
      document.body.classList.add("modal-open");
    },
    viewInteractionDetail: function(value) {
      this.showInteractionDetail = true
      this.interactionData = value
      document.body.classList.add("modal-open");
    },

    editInteraction: function(item) {
      this.editData = item
      this.showInteraction = true
      this.interactionTitle = 'Edit Interaction'
      document.body.classList.add("modal-open");
    },

    closeViewInteractionModal: function() {
      this.showInteractionDetail = false
      setTimeout(() => {
        document.body.classList.remove("modal-open");
      }, 200)
    },

    closeInteractionCommentsdModal: function() {
      this.showInteractionComments = false
      setTimeout(() => {
        document.body.classList.remove("modal-open");
      }, 200)
    },

    closeInteractionModal: function() {
      this.showInteraction = false

      setTimeout(() => {
        this.getData()
        document.body.classList.remove("modal-open");
      }, 200)
    },

    closeInteractionCompletedModal: function() {
      this.showInteractionCompleted = false

      setTimeout(() => {
        this.getData()
        document.body.classList.remove("modal-open");
      }, 200)
    },

    meetingsCreated: function(items) {
      const user = this.currentUser
      const createdItems = []
      items.forEach((item) => {
        if(item.created_by == user && !item.is_completed) {
          createdItems.push(item)
        }
      });
      
      const finalItems = {}
      createdItems.forEach((items) => {
        let date = ''
        if(items.interaction_date == 'TBD') {
          date = 'TBD'
        } else {
          date = items.interaction_date.split('T')[0]
        }
        if(finalItems[date]) {
          finalItems[date].push(items);
        } else {
          finalItems[date] = [items]
        }
      })
      const sortedArray = this.sortObj(finalItems)
  
      return sortedArray
    },


    meetingsAttending: function(items) {
      console.log(items)
      const user = this.currentUser
      const attendingItems = []
      items.forEach((item) => {
        if(item.users_attending){

        
          const found = item.users_attending.some(el => el.name === user)
          // if(item.user_attending_interaction && item.users_attending.includes(user) && !item.is_completed) {
          //   attendingItems.push(item)
          // }
          if(found && !item.is_completed) {
            attendingItems.push(item)
          }
        }
      });
      
      const finalItems = {}
      attendingItems.forEach((items) => {
        let date = ''
        if(items.interaction_date == 'TBD') {
          date = 'TBD'
        } else {
          date = items.interaction_date.split('T')[0]
        }
        if(finalItems[date]) {
          finalItems[date].push(items);
        } else {
          finalItems[date] = [items]
        }
      })
      console.log(finalItems)
      const sortedArray = this.sortObj(finalItems)
  
      return sortedArray
    },

    completedMeetings: function(items) {
      const completedItems = []
      const user = this.currentUser
      items.forEach((item) => {
        if(item.is_completed) {
          const found = item.users_attending.some(el => el.name === user)
          if(found) {
            completedItems.push(item)
          }
          
        }
      });

      const finalItems = {}
      completedItems.forEach((items) => {
        let date = ''
        if(items.interaction_date == 'TBD') {
          date = 'TBD'
        } else {
          date = items.interaction_date.split('T')[0]
        }
        if(finalItems[date]) {
          finalItems[date].push(items);
        } else {
          finalItems[date] = [items]
        }
      })
      const sortedArray = this.sortObj(finalItems)
  
      return sortedArray
    },

    allInteractions: function(items) {
      const finalItems = {}
      items.forEach((item) => {
        let date = ''
        if(item.interaction_date == 'TBD') {
          date = 'TBD'
        } else {
          date = item.interaction_date.split('T')[0]
        }
        if(finalItems[date]) {
          finalItems[date].push(item);
        } else {
          finalItems[date] = [item]
        }
      })
      console.log('final', finalItems)
      const sortedArray = this.sortObj(finalItems)
      
      return sortedArray
    },

    formatTime: function(date) {
      if (date == 'TBD') {
        return 'TBD'
      } else {
        const d = new Date(date)
        
        let hr = d.getHours();
        let min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "AM";
        if( hr >= 12 ) {
          ampm = "PM";
        }
        if( hr > 12 ) {
          hr-= 12
        }
        return `${hr}:${min} ${ampm}`;
      }
    },

    formatDate: function(date) {
      if (date == 'TBD') {
        return 'TBD'
      } else {
        const d = new Date(date.split('-'))
        const day = d.getDate();
        const month = d.toLocaleString('default', { month: 'long' });
        const year = d.getFullYear();
        const dayName = d.toLocaleString('default', { weekday: 'long' });

        return `${dayName}, ${month} ${day}, ${year}`
      }
    },

    getImg: function(data) {
      const links = JSON.parse(localStorage.getItem("settings") || '{}').data;

      if (data.photo) {
        return `${links.kol_image_url}${data.photo}`
      } else {
        return `${links.kol_image_url}${data.image}`
      }
      
    },

    showCurrentTab: function(tab) {
      this.currentTab = tab
      this.getItems()
    },

    getItems: function() {
      this.loading = true
      this.hasCompletionForm = JSON.parse(localStorage.getItem("settings") || '{}').data.has_interaction_completion_form
      console.log(this.currentTab)
      switch(this.currentTab) {
        case 'attending': {
            console.log(this.meetingsAttending(this.meetings))
            this.allMeetings = this.meetingsAttending(this.meetings)
            console.log(this.allMeetings)
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        case 'created': {
            this.allMeetings = this.meetingsCreated(this.meetings)
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        case 'completed': {
            this.allMeetings = this.completedMeetings(this.meetings)
            let count = 0
            Object.keys(this.allMeetings).forEach(el => {
              count = count + this.allMeetings[el].length
            })
            this.interactionCount = count
          }
          break;
        default:
          this.allMeetings = this.allInteractions(this.meetings)
          this.interactionCount = this.meetings.length
      }
      this.loading = false
    },
    getData: function() {
      http.get(`v2/conventions/${this.conventionId}/interactions`, {
        headers: authHeader
      }).then((res) => {
        this.meetings = res.data
        console.log(this.meetings)
        this.getItems()
        // this.interactionCount = res.data.length

      })
    },
    interactionCompleted: function(item) {
      console.log(item)
      this.interactionId = item.id
      this.showInteractionCompleted = true
      document.body.classList.add("modal-open");
      console.log('Interaction Completed')
      
    },

    showComments: function(item) {
      console.log(item)
      this.interactionId = item.id
      this.showInteractionComments = true
      document.body.classList.add("modal-open");
      
    },

    didNotShow: function(item) {
      console.log(item)
      const items = {
        "is_completed": true,
        "did_not_show": true
      }

      http.put(`v2/conventions/interactions/${item.id}/did-not-show`, items, {
        headers: authHeader
      }).then((res) => {
        console.log(res)
        this.getData()
      })
    },
    // getKOLs: function () {
    //   console.log(this.clientData.project_url)
    //   axios.get(`${this.clientData.project_url}${this.clientData.db_name}/kols`).then(res => {
    //      console.log(res)
    //      this.items = res.data.kols
    //    })
    // },

    getDataKOLs: function () {
       axios.get(`https://simpsonapi.com/v2/conventions/3x-data/${localStorage.getItem('project')}`).then(res => {
         console.log(res)
         this.clientData = res.data.data
         //this.getKOLs()
       })
    },
  },
  mounted () {
    this.getData()
    this.getDataKOLs()
  }
  // updated () {
  //   this.getData()
  // }
}
</script>
<style scoped>
body.modal-open {
  overflow: hidden;
}

.header {
  padding-bottom: 16px;
}
.custom-card {
  border-top: 1px solid rgba(0,0,0,.125);
}

.add-btn {
  height: 40px;
  font-weight: 600;
}

.back-icon {
  font-size: 22px;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
  color: #000;
}

/* .card {
  height: 115px;
} */

.created-css {
  margin-top: -10px;
}

.other-tab-css {
  margin-top: 14px;
}

.created-css-2 {
  margin-top: -20px;
}

.other-tab-css-2 {
  margin-top: -6px;
}

.title {
  padding-left: 15px;
}

.title > h3 {
  margin-bottom: 0px;
  font-family: Helvetica;
  font-weight: bold;
}

.title > small {
  font-size: 14px;
  margin-top: 0px;
}

.list-item {
  padding-left: 16px;
}

.list-item > h2 {
  font-size: 22px;
  margin-bottom: 0px;
}

.list-item > h4 {
  font-size: 16px;
  margin-bottom: 0px;
}

.list-item > p {
  font-size: 14px;
  margin-bottom: 0px;
}

.list-img {
  margin-top: 5px
}

.list-group-item+.list-group-item {
    border-top-width: 1px;
}

.interaction-type {
  margin-bottom: 16px;
}

.flight-types {
  display: flex;
  max-width: 336px;
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: 1;
  margin: 0 auto;
  font-size: 12px;
  border: 1px solid #000;
  border-radius: 3px;
  color: #000;
  font-weight: bold;
}
.flight-types > input {
  display: none;
}
.flight-types > input:checked + label {
  color: #fff;
}
.flight-types > input:nth-of-type(1):checked ~ label:last-of-type:before {
  transform: translateX(calc(0% + 0px));
}
.flight-types > input:nth-of-type(2):checked ~ label:last-of-type:before {
  transform: translateX(calc(100% + 0px));
}
.flight-types > input:nth-of-type(3):checked ~ label:last-of-type:before {
  transform: translateX(calc(200% + 0px));
}
.flight-types > input:nth-of-type(4):checked ~ label:last-of-type:before {
  transform: translateX(calc(300% + 0px));
}
.flight-types label {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.flight-types label:last-of-type:before {
  content: "";
  display: block;
  max-width: calc(25% - 0px);
  margin: 0px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translateX(0);
}
.flight-types label {
  padding: 6px 3px;
  margin-bottom: 0px;
  transition: color 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:before {
  background: #006bb6;
  transition: all 250ms cubic-bezier(0, 0.95, 0.38, 0.98);
}
.flight-types label:not(:last-child) {
  border-right: 1px solid #000;
}
</style>
